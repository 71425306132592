import { UserProps, useSessionContext } from '@contexts/session'
import { getFirstNameAndLastName } from '@lib/utils/common'
import { checkSupportedDevice } from '@lib/utils/system-check'

export const getSegmentTrackingDict = (userProps: UserProps) => ({
  userId: userProps?.id,
  studentId: userProps?.student?.id,
  name: userProps?.name,
  phone: userProps?.phone,
  email: userProps?.email,
  grade: userProps?.student?.grade,
  gender: userProps?.gender,
  hasLaptop: userProps?.hasLaptop,
  signupSource: userProps?.signupSource,
})

/**
 * This should house all the segment events
 */
export enum SegmentEventEnum {
  LOGIN_CLICK_LOGIN_NEW_USER = 'web:login:click_login_new_user',
  LOGIN_CLICK_LOGIN_EXISTING_USER = 'web:login:click_login_existing_user',
  TRIAL_SHORT_LINK_VISIT = 'web:trial_class_short_link:visit',
  TRIAL_SHORT_LINK_ENTER_PASSCODE = 'web:trial_class_short_link:enter_passcode',
  TRIAL_SHORT_LINK_SUCCESSFUL_PHONE_VERIFICATION = 'web:trial_class_short_link:successful_phone_verification',
  TRIAL_SHORT_LINK_CLICK_JOIN_CLASS = 'web:trial_class_short_link:click_join_class',
  JOIN_CLASS_CLICK_RESCHEDULE_CLASS = 'web:trial_class_join:student:click_reschedule_class',
  JOIN_CLASS_CLICK_CANCEL_CLASS = 'web:trial_class_join:student:click_cancel_class',
  JOIN_CLASS_CANCEL_CLASS_ENTER_REASON = 'web:trial_class_join:student:cancel_class:enter_reason',
  JOIN_CLASS_CANCEL_CLASS_CLICK_CANCEL_CLASS = 'web:trial_class_join:student:cancel_class:click_cancel_class',
  JOIN_CLASS_CANCEL_CLASS_CLICK_GO_BACK = 'web:trial_class_join:student:cancel_class:click_go_back',
  JOIN_CLASS_RESCHEDULE_CLASS_CLICK_DATE = 'web:trial_class_join:student:reschedule_class:click_date',
  JOIN_CLASS_RESCHEDULE_CLASS_CLICK_TIME = 'web:trial_class_join:student:reschedule_class:click_time',
  JOIN_CLASS_RESCHEDULE_CLASS_CLICK_CONFIRM_RESCHEDULING = 'web:trial_class_join:student:reschedule_class:click_confirm_rescheduling',
  JOIN_CLASS_RESCHEDULE_CLASS_CLICK_GO_BACK = 'web:trial_class_join:student:reschedule_class:click_go_back',
  JOIN_CLASS_RESCHEDULE_CLASS_SUCCESS_CLICK_VISIT_DASHBOARD = 'web:trial_class_join:student:reschedule_class:success:click_visit_dashboard',
  TRIAL_SLOTS_CLICK_DATE = 'web:trial_class_slots:student:click_date',
  TRIAL_SLOTS_CLICK_TIME = 'web:trial_class_slots:student:click_time',
  LANGUAGE_PREFERENCE_VISITED = 'web:language_preference:student:visit',
  LANGUAGE_PREFERENCE_LANGUAGE_SELECTED = 'web:language_preference:student:select_language',
  LANGUAGE_PREFERENCE_CLICKED_NEXT = 'web:language_preference:student:click_next',

  MOD_LOGIN_MODAL_OPEN = 'web:teacher_mod_login:modal_open',
  MOD_LOGIN_STUDENT_SELECT = 'web:teacher_mod_login:student_select',
  MOD_LOGIN_CLICK_LOGIN_CTA = 'web:teacher_mod_login:click_login_cta',
  MOD_LOGIN_CLICK_SUCCESSFUL = 'web:teacher_mod_login:login_successful',

  PROJECT_DETAILS_CLICK_SUBMIT = 'web:project_details:student:click_submit_project',
  PROJECT_DETAILS_CLICK_CONFIRM = 'web:project_details:student:click_submit_project_confirm',
  PROJECT_DETAILS_AHA_MODAL_OPEN = 'web:project_submission_success:student:visit_modal',
  PROJECT_DETAILS_AHA_MODAL_FORM_SUBMIT = 'web:project_submission_success_aha_modal:student:click_referral_form_cta',
  PROJECT_DETAILS_AHA_MODAL_CLICK_WHATSAPP = 'web:project_submission_success_aha_modal:student:click_referral_whatsapp_cta',
  PROJECT_DETAILS_AHA_MODAL_CLICK_FACEBOOK = 'web:project_submission_success_aha_modal:student:click_referral_facebook_cta',
  PROJECT_DETAILS_AHA_MODAL_CLICK_COPY = 'web:project_submission_success_aha_modal:student:click_referral_copy_cta',

  CONVERSION_SIGNUP_COMPLETED = 'web:student:conversion:signup_completed',
  CONVERSION_FIRST_CLASS_BOOKED = 'web:student:conversion:first_class_booked',
  CONVERSION_FIRST_CLASS_COMPLETED = 'web:student:conversion:first_class_completed',
  CONVERSION_FREE_CLASS_COMPLETED = 'web:student:conversion:free_class_completed',
  CONVERSION_COURSE_PURCHASED = 'web:student:conversion:course_purchased',
  COMPETITION_PAGE_BUY_NOW_CLICK = 'web:competition_page:student:click_buy_now',
  COMPETITION_THANK_YOU_PAGE_BUY_NOW_CLICK = 'web:competition_thank_you_page:student:click_buy_now',

  HPE_CODEBATTLE_REGISTRATION_COMPLETED = 'web:student:hpe_codebattle:registration_completed',

  HACKATHON_SUBMISSION_CLICK_SUBMIT = 'web:hackathon_page:student:click_submit_project',
  HACKATHON_SUBMISSION_THANK_YOU_PAGE_VISIT = 'web:hackathon_submission_success_page:student:visit',
  HACKATHON_SUBMISSION_THANK_YOU_PAGE_CLICK_FORM_SUBMIT = 'web:hackathon_submission_success_page:student:click_referral_form_cta',
  HACKATHON_SUBMISSION_THANK_YOU_PAGE_CLICK_WHATSAPP = 'web:hackathon_submission_success_page:student:click_referral_whatsapp_cta',
  HACKATHON_SUBMISSION_THANK_YOU_PAGE_CLICK_FACEBOOK = 'web:hackathon_submission_success_page:student:click_referral_facebook_cta',
  HACKATHON_SUBMISSION_THANK_YOU_PAGE_CLICK_COPY = 'web:hackathon_submission_success_page:student:click_referral_copy_cta',

  STUDENT_DASHBOARD_REFERRAL_PAGE_VISIT = 'web:dashboard:student:invite_friends_page:visit',
  STUDENT_DASHBOARD_REFERRAL_PAGE_CLICK_FORM_SUBMIT = 'web:dashboard:student:invite_friends_page:click_referral_form_cta',
  STUDENT_DASHBOARD_REFERRAL_PAGE_CLICK_WHATSAPP = 'web:dashboard:student:invite_friends_page:click_referral_whatsapp_cta',
  STUDENT_DASHBOARD_REFERRAL_PAGE_CLICK_FACEBOOK = 'web:dashboard:student:invite_friends_page:click_referral_facebook_cta',
  STUDENT_DASHBOARD_REFERRAL_PAGE_CLICK_COPY_LINK = 'web:dashboard:student:invite_friends_page:click_referral_copy_cta',

  TEACHER_DASHBOARD_REFERRAL_PAGE_VISIT = 'web:dashboard:teacher:invite_teachers_page:visit',
  TEACHER_DASHBOARD_REFERRAL_PAGE_CLICK_FORM_SUBMIT = 'web:dashboard:teacher:invite_teachers_page:click_referral_form_cta',
  TEACHER_DASHBOARD_REFERRAL_PAGE_CLICK_WHATSAPP = 'web:dashboard:teacher:invite_teachers_page:click_referral_whatsapp_cta',
  TEACHER_DASHBOARD_REFERRAL_PAGE_CLICK_FACEBOOK = 'web:dashboard:teacher:invite_teachers_page:click_referral_facebook_cta',
  TEACHER_DASHBOARD_REFERRAL_PAGE_CLICK_COPY_LINK = 'web:dashboard:teacher:invite_teachers_page:click_referral_copy_cta',

  TRIAL_CLASS_COMPLETE_PAGE_VISIT = 'web:trial_class_complete_page:student:visit',
  TRIAL_CLASS_COMPLETE_PAGE_CLICK_FORM_SUBMIT = `web:trial_class_complete_page:student:click_referral_form_cta`,
  TRIAL_CLASS_COMPLETE_PAGE_CLICK_WHATSAPP = `web:trial_class_complete_page:student:click_referral_whatsapp_cta`,
  TRIAL_CLASS_COMPLETE_PAGE_CLICK_FACEBOOK = `web:trial_class_complete_page:student:click_referral_facebook_cta`,
  TRIAL_CLASS_COMPLETE_PAGE_CLICK_COPY_LINK = `web:trial_class_complete_page:student:click_referral_copy_cta`,

  TRIAL_CLASS_COMPLETE_REFERRAL_PAGE_VISIT = 'web:trial_class_complete_referral_page:student:visit',
  TRIAL_CLASS_COMPLETE_REFERRAL_PAGE_CLICK_FORM_SUBMIT = `web:trial_class_complete_referral_page:student:click_referral_form_cta`,
  TRIAL_CLASS_COMPLETE_REFERRAL_PAGE_CLICK_WHATSAPP = `web:trial_class_complete_referral_page:student:click_referral_whatsapp_cta`,
  TRIAL_CLASS_COMPLETE_REFERRAL_PAGE_CLICK_FACEBOOK = `web:trial_class_complete_referral_page:student:click_referral_facebook_cta`,
  TRIAL_CLASS_COMPLETE_REFERRAL_PAGE_CLICK_COPY_LINK = `web:trial_class_complete_referral_page:student:click_referral_copy_cta`,

  STUDENT_DASHBOARD_MAIN_PAGE_VISIT = 'web:dashboard_main_page:student:visit',
  STUDENT_DASHBOARD_MAIN_PAGE_CLICK_FORM_SUBMIT = 'web:dashboard_main_page:student:click_referral_form_cta',
  STUDENT_DASHBOARD_MAIN_PAGE_CLICK_WHATSAPP = 'web:dashboard_main_page:student:click_referral_whatsapp_cta',
  STUDENT_DASHBOARD_MAIN_PAGE_CLICK_FACEBOOK = 'web:dashboard_main_page:student:click_referral_facebook_cta',
  STUDENT_DASHBOARD_MAIN_PAGE_CLICK_COPY_LINK = 'web:dashboard_main_page:student:click_referral_copy_cta',

  COMPETITION_PAGE_REFERRAL_BANNER_CLICK = 'web:competition_page:student:click_referral_banner',
  COMPETITION_REFERRAL_PAGE_VISIT = 'web:competition_invite_page:student:visit',
  COMPETITION_REFERRAL_PAGE_CLICK_WHATSAPP = 'web:competition_invite_page:student:click_referral_whatsapp_cta',
  COMPETITION_REFERRAL_PAGE_CLICK_FACEBOOK = 'web:competition_invite_page:student:click_referral_facebook_cta',
  COMPETITION_REFERRAL_PAGE_CLICK_COPY_LINK = 'web:competition_invite_page:student:student:click_referral_copy_cta',

  STUDENT_DASHBOARD_REFERRAL_MODAL_VISIT = 'web:dashboard_main_page:student:referral_modal:visit',
  STUDENT_DASHBOARD_REFERRAL_MODAL_CLICK_FORM_SUBMIT = 'web:dashboard_main_page:student:referral_modal:click_referral_form_cta',
  STUDENT_DASHBOARD_REFERRAL_MODAL_CLICK_WHATSAPP = 'web:dashboard_main_page:student:referral_modal:click_referral_whatsapp_cta',
  STUDENT_DASHBOARD_REFERRAL_MODAL_CLICK_FACEBOOK = 'web:dashboard_main_page:student:referral_modal:click_referral_facebook_cta',
  STUDENT_DASHBOARD_REFERRAL_MODAL_CLICK_COPY_LINK = 'web:dashboard_main_page:student:referral_modal:click_referral_copy_cta',

  TEACHER_CLASSROOM_CALL_STUDENT_CTA_CLICK = 'web:classroom:teacher:click_call_student_cta',
  TEACHER_CLASSROOM_WHATSAPP_REMINDER_CTA_APPEARS = 'web:classroom:teacher:send_whatsapp_reminder_cta_appears',
  TEACHER_CLASSROOM_WHATSAPP_REMINDER_CTA_CLICK = 'web:classroom:teacher:click_send_whatsapp_reminder_cta',
  TEACHER_CLASSROOM_WHATSAPP_REMINDER_SEND_SUCCESS = 'web:classroom:teacher:whatsapp_reminder_send_success',
  TEACHER_CLASSROOM_WHATSAPP_REMINDER_SEND_ERROR = 'web:classroom:teacher:whatsapp_reminder_send_error',

  TRIAL_CLASS_BOOKING_FLOW_VISITED_SYSTEM_CONFIRMATION_PAGE = 'web:system_confirmation:student:visit',
  TRIAL_CLASS_BOOKING_FLOW_CLICK_HAS_COMPUTER = 'web:system_confirmation:student:click_has_computer',
  TRIAL_CLASS_BOOKING_FLOW_CLICK_HAS_NO_COMPUTER = 'web:system_confirmation:student:click_has_no_computer',
  TRIAL_CLASS_BOOKING_FLOW_CLICK_BOOK_NOW = 'web:trial_class_booking:student:click_book_now',
  TRIAL_CLASS_BOOKING_FLOW_CLICK_CHANGE_SLOT = 'web:trial_class_booking:student:confirmation_modal:click_change_slot',
  TRIAL_CLASS_BOOKING_FLOW_CLICK_YES_I_WILL_ATTEND = 'web:trial_class_booking:student:confirmation_modal:click_yes_confirm',

  STUDENT_DASHBOARD_UNPAID_INVOICE_MODAL_SEEN = 'web:dashboard:student:unpaid_invoice_modal:seen',
  STUDENT_DASHBOARD_UNPAID_INVOICE_MODAL_CLICK_REHARGE_NOW = 'web:dashboard:student:unpaid_invoice_modal:click_recharge_now',
  STUDENT_DASHBOARD_UNPAID_INVOICE_MODAL_CLICK_ALREADY_PAID = 'web:dashboard:student:unpaid_invoice_modal:click_already_paid',
  STUDENT_DASHBOARD_UNPAID_INVOICE_MODAL_WAITING_SCREEN = 'web:dashboard:student:unpaid_invoice_modal:waiting_screen',
  STUDENT_DASHBOARD_UNPAID_INVOICE_MODAL_PAYMENT_FAILED = 'web:dashboard:student:unpaid_invoice_modal:payment_failed',
  STUDENT_DASHBOARD_UNPAID_INVOICE_MODAL_PAYMENT_SUCCESS = 'web:dashboard:student:unpaid_invoice_modal:payment_success',
  STUDENT_DASHBOARD_UNPAID_INVOICE_MODAL_CLICK_CANCEL = 'web:dashboard:student:unpaid_invoice_modal:click_cancel',
}

const useSegment = () => {
  const {
    id,
    student,
    name,
    phone,
    email,
    gender,
    hasLaptop,
    signupSource,
  } = useSessionContext()

  const segmentTrackingDict = {
    userId: id,
    studentId: student?.id,
    name,
    phone,
    email,
    grade: student?.grade,
    gender,
    hasLaptop,
    signupSource,
    device: checkSupportedDevice().device,
    ...getFirstNameAndLastName(name),
  }
  /**
   * @param event The event name to be sent to segment, refer to the SegmentEventType enum
   * @param payload
   * Additional data that needs to be sent along with the base data (session details).
   * The type is a flat object with a string or a number as its value
   */
  const track = (
    event: SegmentEventEnum,
    payload?: Record<string, string | number | boolean>
  ) => {
    if (typeof window === 'undefined') return
    if (segmentTrackingDict?.userId) {
      window.globalThis?.analytics?.track(event, {
        ...segmentTrackingDict,
        ...payload,
      })
    }
  }

  return track
}

export default useSegment
